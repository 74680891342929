import Vue from 'vue'
import Vuetify from 'vuetify'
import 'babel-polyfill' // Required for IE11 and Safari 9 by Vuetify
import 'vuetify/dist/vuetify.min.css'

// Helpers
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify, {
  theme: {
    primary: colors.cyan.darken1 // #00ACC1
  }
})
